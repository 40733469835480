@mixin responsive($query) {
  @media (max-width: + $query) {
    @content;
  }
}

$tivibuSoftBlueColor: #49bed8;

.slick-track {
  margin: 0 !important;
}

.vod-item {
  position: relative;
  padding: 0.78vw;
  outline: none;
  // margin-right: 30px;

  &:hover {
    .add-list-icon-row {
      opacity: 1;
    }
  }
}

.carousel-item {
  position: relative;
  display: flex;
  flex-direction: column;
  transition: all ease 0.3s;
  overflow: hidden;
  cursor: pointer;
  outline: none !important;
  border-radius: 20px;
}

.bookmark-progress {
  position: absolute;
  width: calc(100% - 1.56vw);
  height: 1px;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.4);

  .progress {
    height: 100%;
    width: 0;
    background-color: #00deff;
  }
}

.record-title {
  margin: 1.7vw 0;
  font-family: SFUIText;
  font-size: 1vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  opacity: 0.9;
}

.add-list-icon-row {
  opacity: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  width: -webkit-fill-available;
  height: 50px;

  transition: all ease 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $tivibuSoftBlueColor;
  font-size: 16px;
  cursor: pointer;
  margin: 0 5px;

  .fav-icon {
    display: flex;
    align-items: center;
    font-size: 25px;
    margin-right: 10px;
  }

  &:hover {
    color: white;
    border-color: white;
  }

  @include responsive(1400px) {
    font-size: 12px;
  }
}

.carousel-poster {
  width: 100%;
  height: auto;
  transition: all ease 0.3s;
}

.label-title {
  display: inline-flex;
  align-items: center;
  width: -webkit-fill-available;
  font-family: ProximaNova;
  font-size: 1.25vw;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.64px;
  text-align: left;
  color: #fff;
  cursor: pointer;

  &:hover .see-all-icon {
    opacity: 1;
    margin-left: 30px;
  }

  .see-all-icon {
    opacity: 0;
    width: 12px;
    height: 18.5px;
    margin-left: 0;
    object-fit: contain;
    transition: all ease 0.3s;
  }

  .go-back-icon {
    width: 12px;
    height: 18.5px;
    margin-right: 20px;
    margin-left: 0;
    object-fit: contain;
    transform: rotate(180deg);
  }
}

.slick-dots li.slick-active button:before {
  color: #49bed8 !important;
  font-size: 10px;
}

.slick-dots li button:before {
  font-size: 10px !important;
}

.slick-wrapper {
  position: relative;
  left: -15px;
  // margin-top: 1.6vw;
}

.content-carousel {
  position: relative;
  // margin-top: 25px;
  padding-left: 90px;
  padding-right: 90px;
  margin-bottom: 2vw;
}

.slider-row {
  position: relative;

  &:hover {
    .direction-button {
      opacity: 1;
      z-index: 1;
    }
  }

  .direction-button {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: calc(50% - 35px);
    background: rgba(12, 25, 39);
    // background: rgba(12, 25, 39, 0.85);
    width: 70px;
    height: 70px;
    border-radius: 50%;
    // height: -webkit-fill-available !important;
    color: white;
    opacity: 0;
    z-index: -1;
    transition: all ease 0.3s;
    cursor: pointer;
    font-size: 12px;

    &.prev {
      // width: 100px;
      left: 10px;
      // height: 100%;

      span {
        transform: rotate(180deg);
      }
    }

    &.next {
      right: 30px;
      // height: 100%;
    }

    &.record {
      top: calc(50% - 35px - 2.2vw);
    }
  }
}

.carousel-title-2 {
  position: relative;
  margin: 33px 0;
  height: 30px;
  overflow: hidden;
  white-space: nowrap;

  .overflow {
    position: absolute;
    top: 0;
    left: 0;
    font-family: SFUIText;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    // margin-left: 10px;
    opacity: 0.9;
    white-space: nowrap;
    transition: all ease 0.5s;
  }
}

.dot-options {
  display: flex;
  position: absolute;
  width: 40px;
  height: 25px;
  justify-content: center;
  align-items: center;
  right: 15px;
  cursor: pointer;

  .dots-wrapper {
    display: flex;
    position: relative;

    .dot {
      width: 5px;
      height: 5px;
      background: #d8d8d8 0% 0% no-repeat padding-box;
      margin: 0 2px;
      border-radius: 50%;
    }

    .options-box {
      display: none;
      position: absolute;
      top: 10px;
      right: 3px;
      // background-color: chartreuse;
      flex-direction: column;
      align-items: flex-end;
      z-index: 15;

      &.show {
        display: flex;
      }

      .tri-angle {
        border-color: transparent #ffffff33 transparent transparent;
        border-style: solid;
        border-width: 20px 20px 0px 0;
        backdrop-filter: blur(16px);
        -webkit-backdrop-filter: blur(16px);
        width: 0;
        height: 0;
      }

      .options {
        background: #ffffff33 0% 0% no-repeat padding-box;
        backdrop-filter: blur(16px);
        -webkit-backdrop-filter: blur(16px);
        width: max-content;
        padding: 10px 20px;
        border-radius: 2px;

        .option {
          display: flex;
          align-items: center;
          font-family: SFUIText;
          font-size: 13px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.69;
          letter-spacing: normal;
          color: #fff;
          cursor: pointer;

          span {
            display: flex;
            margin-left: 10px;
          }
        }
      }
    }
  }
}
