@import "../../style/common";

$white: #fff;

.login-page-container {
  width: 100%;
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  padding-bottom: 60px;

  .go-back-icon {
    position: absolute;
    left: 82px;
    top: 32px;
    cursor: pointer;

    svg {
      width: 33px;
      height: 29px;
    }
  }

  .login-content {
    width: 100%;
    margin-top: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .atlas-logo {
      width: 200px; //107px
    }

    .login-line {
      background-color: $white;
      height: 1px;
      width: 300px;
      margin: 20px 0px;
      opacity: 0.1;
    }

    .article-2 {
      @include login-text(SFUITextLight, 14px, 14px, 1%);
      width: 100%;
      line-height: 16px;
    }

    .sign-up-button {
      @include login-button(
        SFUITextBold,
        #d5dc36,
        1.5%,
        #0c1927,
        300px,
        45px,
        45px
      );
    }
  }
}

@media (width >=1920px) {
  .login-page-container {
    .login-content {
      .atlas-logo {
        width: 200px; //133px
      }

      .login-line {
        width: 450px;
        margin: 30px 0px;
      }

      .sign-up-button {
        @include login-button(
          SFUITextBold,
          #d5dc36,
          1.5%,
          #0c1927,
          450px,
          65px,
          65px
        );
      }
    }
  }
}

@media (height >=900px) {
  .login-page-container {
    .login-content {
      margin-top: 5%;
    }
  }
}
