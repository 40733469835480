@import "../../style/common";

$white: #fff;

.login-title {
  width: 63.75%;
  @include login-text(Swis721HvBT, 36px, auto, 2%);
}

.input-number {
  @include login-input(300px, 45px);
  margin-top: 3.1%;
}

.password-section {
  position: relative;
  margin-top: 30px;
  .input-password {
    @include login-input(300px, 45px);
  }

  .eye-icon {
    cursor: pointer;
    width: 24px;
    height: 16px;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.input-error {
  @include input-error(300px);
}

.sub-section {
  display: flex;
  justify-content: space-between;
  width: 300px;
  font-family: SFUITextLight;
  font-size: 14px;
  color: #fff;
  margin-top: 21px;

  .input-checkbox {
    display: flex;
    align-items: center;
    gap: 12px;
    input {
      width: 18px;
      height: 18px;
      vertical-align: middle;
      display: none;
      cursor: pointer;
    }

    label {
      position: relative;
      cursor: pointer;
    }

    label:before {
      content: "";
      -webkit-appearance: none;
      background-color: $white;
      border-radius: 2px;
      border: none;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
        inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
      padding: 10px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 10px;
    }

    input:checked + label:after {
      content: "";
      display: block;
      position: absolute;
      top: 3px;
      left: 7px;
      width: 4px;
      height: 10px;
      border: solid #000000;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  a {
    display: flex;
    align-items: center;
  }
}

.continue-button {
  @include login-button(
    SFUITextSemibold,
    $white,
    2%,
    #0c1927,
    300px,
    45px,
    45px
  );
}

.change-password-button {
  @include login-button(
    SFUITextSemibold,
    transparent,
    1.1%,
    #d5dc36,
    300px,
    45px,
    45px
  );
  border: 1px solid #d5dc36;
}

.loading {
  width: 24vw;
  height: 24vw;
  padding: 5vw;
  background: url("../../assets/images/circles.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  animation-name: loading;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  img {
    width: 100%;
    opacity: 0.1;
  }
}

@keyframes loading {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@media (width >= 1920px) and (height >= 900px) {
  .login-title {
    @include login-text(
      Swis721HvBT,
      65px !important,
      auto !important,
      5% !important
    );
  }

  .continue-button {
    @include login-button(
      SFUITextSemibold,
      white,
      2.7%,
      #0c1927,
      450px !important,
      65px !important,
      65px !important
    );
  }
}

@media (width >= 1920px) {
  .input-number {
    @include login-input(450px, 65px);
  }

  .input-error {
    @include input-error(450px);
  }

  .password-section {
    .input-password {
      @include login-input(450px, 65px);
    }
  }

  .sub-section {
    width: 450px;
    margin-top: 2%;
    .input-checkbox {
      //   gap: 12px;
      input {
        width: 25px;
        height: 25px;
      }
    }
  }

  .continue-button {
    @include login-button(
      SFUITextSemibold,
      $white,
      2%,
      #0c1927,
      450px,
      65px,
      65px
    );
  }

  .change-password-button {
    @include login-button(
      SFUITextSemibold,
      transparent,
      1.1%,
      #d5dc36,
      450px,
      65px,
      65px
    );
  }
}

@media (height >= 900px) {
  .login-title {
    @include login-text(Swis721HvBT, 46px, auto, 5%);
  }

  .continue-button {
    @include login-button(
      SFUITextSemibold,
      $white,
      2.7%,
      #0c1927,
      300px,
      45px,
      45px
    );
  }
}
