.footer-container {
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  width: 100%;

  .footer-column {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: #182532; //tasarımdaki rengin color pickerden alınan renk kodu

    //background:#FFFFFF0D; //tasarımda verilen renk kodu
    .footer-top-line {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 70%;
      height: 8.476vw;

      .label {
        text-decoration: none;
        padding-right: 3.12vw;
        font-family: SFUIText;
        font-size: .936vw;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 0.9;
        cursor: pointer;
      }

      .footer-icon {
        display: flex;
        align-items: center;
        padding: 0.26vw;

        .span {
          padding-left: 0.52vw;
          font-size: 2.86vw;
        }

      }
    }
  }

  .footers-column {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: #273341; //tasarımdaki rengin color pickerden alınan renk kodu

    //background: #A5B2C31A; //tasarımda verilen renk kodu
    .footer-bottom-line {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 70%;
      height: 3.9vw;
      opacity: 1;

      .footer-bottom {
        display: flex;
        align-items: center;

        .label {
          text-decoration: none;
          padding-right: 2.444vw;
          letter-spacing: 0px;
          font-size: .73vw;
          font-family: SFUIText;
          color: #707C8B;
          opacity: 0.9;
          cursor: pointer;
        }
      }

      .footers {
        display: flex;
        align-items: center;
        padding: 0.52vw;

        .label {
          letter-spacing: 0px;
          font-size: .73vw;
          font-family: SFUIText;
          color: #707C8B;
          opacity: 0.9;
          cursor: pointer;
        }
      }
    }
  }
}