.profile-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-position: center center;

  .logo-wrapper {
    width: 200px;
    //max-width: 100px;
    //min-width: 100px;

    .atlas-logo {
      max-width: 100%;
      width: 100%;
      height: auto;
      display: block;
    }
  }

  .profile-gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000, $alpha: 0.7);
  }

  .profile-gradient-2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #0c1927, $alpha: 0.65);
  }

  .row {
    display: flex;
    position: relative;
    justify-content: center;
    width: 100%;
    margin-top: 5vw;
    z-index: 10;

    .close {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 130px;
      width: 36px;
      height: 36px;
      background: rgba($color: #ffffff, $alpha: 0.3);
      border: 1px solid rgba($color: #ffffff, $alpha: 0.5);
      border-radius: 50%;
      cursor: pointer;
      z-index: 2;
    }
  }

  .profile-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 8vw;
    z-index: 10;

    .text {
      font-family: Swis721HvBT;
      font-size: 1.82vw;
      line-height: 2vw;
      color: #f9faff;
      margin-bottom: 3vw;
    }

    .profile-items-box {
      display: flex;

      .profile-item {
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: center;
        max-width: 12vw !important;

        .profile-avatar {
          position: relative;
          display: flex;
          flex-direction: column;
          width: 100%;
          height: auto;
          padding: 20px;
          // border-radius: 50%;
          // border: 3px solid transparent;
          transition: all ease 0.3s;
          transition-delay: 0.1s;
          cursor: pointer;

          img {
            width: 100%;
          }

          .settings-icon-wrapper {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            bottom: 0;
            width: calc(100% - 40px);

            .settings-icon {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 2vw;
              height: 2vw;
              font-size: 0.9vw;
              background-color: #112134;
              border: 1px solid #46acc3;
              border-radius: 50%;
              color: white;
            }
          }
        }

        .profile-name {
          font-family: SFUITextBold;
          font-size: 1.25vw;
          color: #ffffff;
          text-align: center;
          max-width: 12vw !important;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;
        }

        .edit-text {
          font-family: SFUIText;
          font-size: 0.75vw;
          color: #00deff;
          text-align: center;
          cursor: pointer;
        }
      }
    }

    .profile-item.add {
      background-color: #999999;
    }
  }

  .pin-code-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin-top: 10vw;
    z-index: 10;

    .text {
      font-family: Swis721HvBT;
      font-size: 3.4vw;
      line-height: 3.7vw;
      color: #f9faff;
      margin-bottom: 3vw;
      margin-top: 7vw;
    }

    .text-logging {
      font-family: Swis721HvBT;
      font-size: 1.85vw;
      color: #f9faff;
      opacity: 0;
      // transition: all ease .3s;
      margin-bottom: 3vw;
      margin-top: 3vw;

      &.show {
        opacity: 1;
      }
    }

    .profile-item {
      width: 100%;
      display: flex;
      position: relative;
      flex-direction: column;
      align-items: center;

      .profile-avatar {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 12vw;
        height: 12vw;
        border-radius: 50%;

        .profile-loading {
          width: 24vw;
          height: 24vw;
          top: -6vw;
          left: -6vw;
          position: absolute;
          background: url("../../assets/images/circles.png");
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          animation-name: profileLoading;
          animation-duration: 2s;
          animation-iteration-count: infinite;
        }

        img {
          width: 100%;
          z-index: 10;
        }
      }

      .profile-name {
        font-family: SFUITextBold;
        font-size: 1.66vw;
        color: rgba($color: #fff, $alpha: 0.9);
        text-align: center;
        margin: 1vw 0;
        z-index: 10;
      }
    }

    .close {
      display: flex;
      align-items: center;
      justify-content: center;
      right: 130px;
      width: 36px;
      height: 36px;
      background: rgba($color: #ffffff, $alpha: 0.3);
      border: 1px solid rgba($color: #ffffff, $alpha: 0.5);
      border-radius: 50%;
      cursor: pointer;
      margin-top: 6vw;
      z-index: 2;
    }
  }
}

#profileListForm {
  width: 20vw;
  margin: auto;
}

@keyframes profileLoading {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
