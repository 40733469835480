@mixin responsive($query) {
  @media (max-width: + $query) {
    @content;
  }
}

.list {
  display: flex;

  .vertical-channel-list {
    display: inline-block;
    //max-height: calc(100vh - 288px);
    max-height: 72.3vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 10px;
    width: 100%;
    scrollbar-color: white transparent;

    @include responsive(1400px) {
      max-height: calc(100vh - 230px);
    }
  }

  .genre-container {

    width: 0;
    transition: all ease 0.3s;
    visibility: hidden;

    &.open {
      visibility: visible;
      width: 40%;
      padding-right: 10px;
    }

    &.open-player {
      position: absolute;
      top: 0;
      left: 0;
      visibility: visible;
      width: 100%;
      z-index: 10;
    }

    .vertical-genre-list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      max-height: calc(100vh - 288px);
      width: 100%;
      border: 1px solid #3D4E5A40;
      border-radius: 4px;
      padding: 20px 20px;
      background-color: #0A1624;
      scrollbar-color: white transparent;
      overflow-y: auto;
      overflow-x: hidden;

      @include responsive(1400px) {
        max-height: calc(100vh - 230px);

      }
    }
  }

  .icon-space {
    display: inline-block;
    width: 10%;
    height: 72vh;
  }


  .vertical-channel-list::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  .vertical-channel-list::-webkit-scrollbar-track {
    background: #152F3E 0% 0% no-repeat padding-box;
    border-radius: 3px;
  }

  /* Handle */
  .vertical-channel-list::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 3px;
  }

  /* Handle on hover */
  .vertical-channel-list::-webkit-scrollbar-thumb:hover {
    background: rgb(209, 209, 209);
  }

  .vertical-genre-list::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  .vertical-genre-list::-webkit-scrollbar-track {
    background: #152F3E 0% 0% no-repeat padding-box;
    border-radius: 3px;

  }

  /* Handle */
  .vertical-genre-list::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: #fff;
  }

  /* Handle on hover */
  .vertical-genre-list::-webkit-scrollbar-thumb:hover {
    background: rgb(209, 209, 209);
  }

  .channel-list-loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .channel-list-loader {
    left: unset !important;
    transform: unset !important;
  }

}