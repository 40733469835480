:root {
  --main-color: #0c1927;
  --bg-color: #f5f7fa;
  --chat-text-color: #fff;
  --soft-blue: #376b7e;
}

@keyframes chatBotButtonOpen {
  from {
    color: var(--main-color);
  }
  to {
    color: var(--chat-text-color);
  }
}

@keyframes chatbotOpen {
  0% {
    transform: translate(100%, 100%) scale(0.7);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0) scale(1);
    opacity: 1;
  }
}

@keyframes chatbotClose {
  0% {
    transform: translate(0, 0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(100%, 100%) scale(0.7);
    opacity: 0;
  }
}

@keyframes chatButtonActive {
  0% {
    scale: 0.5;
  }
  100% {
    scale: 1;
  }
}
@keyframes chatMessageOpen {
  0% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes chatbotButtonHover {
  0% {
    transform: scale(1);
    color: var(--main-color);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
    color: var(--chat-text-color);
  }
}

@keyframes tickAppear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.message-status {
  font-size: 9px;
  color: #bbb;
  margin-left: 5px;
  display: flex;
  align-items: flex-end;
}

.tick {
  color: #bbb;
  animation: tickAppear 0.3s ease-in-out;
}

.double-tick {
  color: #4fc3f7;
  animation: tickAppear 0.3s ease-in-out;
}

.react-chatbot-kit-chat-header {
  display: none;
}

.chatbot-button {
  background-color: var(--soft-blue);
  border: none;
  border-radius: 25px;
  bottom: 15px;
  height: 50px;
  position: fixed;
  right: 40px;
  width: 50px;
  z-index: 6;
  &:hover {
    animation: chatbotButtonHover 0.75s ease-in-out;
    svg {
      transform: rotate(360deg);
      transition: transform 0.75s ease-in-out;
    }
  }
}

.active {
  animation: chatButtonActive 0.2s ease-in-out;
  svg {
    color: var(--chat-text-color);
  }
}

.chatbot-window {
  position: fixed;
  bottom: calc(100 / 1080 * 100vh);
  right: calc(50 / 1920 * 100vw);
  padding: calc(5 / 1080 * 100vh);
  z-index: 1000;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transform-origin: bottom left;

  &.open {
    animation: chatbotOpen 0.7s ease forwards;
  }

  &.close {
    animation: chatbotClose 0.7s ease forwards;
  }
}

.react-chatbot-kit-chat-container {
  width: 50vw;
  .react-chatbot-kit-chat-inner-container {
    background-color: var(--bg-color);

    .react-chatbot-kit-chat-message-container {
      width: 100%;
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
      background-color: var(--bg-color);
      height: 90%;
    }
  }
}

.react-chatbot-kit-chat-input::placeholder {
  color: #999;
  font-size: 0.9rem;
}

.react-chatbot-kit-chat-input-container {
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  background-color: var(--bg-color);
  border-top: 1px solid var(--main-color);
  height: 10%;
}

.react-chatbot-kit-chat-input-form {
  display: flex;
  width: 100%;
  background-color: var(--bg-color);
}

.react-chatbot-kit-user-avatar-container,
.react-chatbot-kit-chat-bot-avatar-container {
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.react-chatbot-kit-chat-input {
  width: 100%;
  padding: calc(25 / 1080 * 100vh);
  font-size: 0.9rem;
  background-color: var(--bg-color);
  border: none;
  border-radius: 15px;
  color: #000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  resize: none; /* Kullanıcının boyutlandırmasını engelle */
  height: auto; /* Yüksekliğin otomatik ayarlanmasını sağla */
  max-height: 5rem; /* Maksimum 3 satır için yüksekliği sınırla */
  overflow-y: auto; /* Maksimum yüksekliğe ulaştığında kaydırma ekle */
  line-height: 1.5rem; /* Her satırın yüksekliğini belirle */
  min-height: 1.5rem; /* Minimum yükseklik olarak 1 satır belirle */

  &:focus {
    outline: none;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
}

.react-chatbot-kit-chat-bot-message-container {
  display: flex;
  justify-content: flex-start;
  margin: 1vw 0;
  padding: 0vw 1vw;
  animation: chatMessageOpen 0.5s ease-in-out;
  transform-origin: top left;

  .react-chatbot-kit-chat-bot-message {
    width: auto;
    min-width: 70%;
    max-width: 90%;
    padding: 10px;
    margin: 0;
    background-color: var(--main-color);
    color: var(--chat-text-color);
    border-radius: 15px 15px 15px 0px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    font-family: SFUIText;
    font-size: 14px;
    line-height: 1.31;
    text-align: left;
    animation: chatMessageOpen 0.5s ease-in-out;
    transform-origin: top left;

    .react-chatbot-kit-chat-bot-message a {
      color: var(--soft-blue);
    }
    .react-chatbot-kit-chat-bot-message li {
      padding: 0.5vw 0;
    }
    .react-chatbot-kit-chat-bot-message li::marker {
      color: var(--soft-blue);
    }
  }
}

.react-chatbot-kit-user-chat-message-container {
  display: flex;
  justify-content: flex-end;
  margin: 1vw 0;
  padding: 1vw;
  animation: chatMessageOpen 0.5s ease-in-out;
  transform-origin: top right;

  .react-chatbot-kit-user-chat-message {
    width: auto;
    min-width: 70%;
    max-width: 90%;
    padding: 10px;
    margin: 0;
    background-color: var(--soft-blue);
    color: var(--chat-text-color);
    border-radius: 15px 15px 0px 15px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    font-family: SFUIText;
    font-size: 14px;
    line-height: 1.31;
    white-space: pre-wrap;
    animation: chatMessageOpen 0.5s ease-in-out;
    transform-origin: top right;
    word-break: break-word;
  }
}

.react-chatbot-kit-chat-btn-send {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--soft-blue);
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-bottom-right-radius: 0px;
}

.react-chatbot-kit-chat-bot-message-arrow,
.react-chatbot-kit-user-chat-message-arrow {
  display: none;
}
