@mixin responsive($query) {
    @media (max-width: + $query) {
        @content;
    }
}

.container {
    width: 100%;
    // height: 100vh;
    background: #0C1927 0% 0% no-repeat padding-box;
    padding-top: 70px;
    margin-bottom:2.6vw;

    .filter-row {
        width: 79.6%;
        margin: auto;
        display: flex;
        margin-top: .78vw;
        //margin-top: 3.12vw;
        font-family: SFUIText;
        font-size: 16px;
        color: white;
        flex-direction: column;

        @include responsive(1400px) {
            width: 75%;
            margin-top: 30px;
            margin-bottom: 30px;
            font-size: 14px;
        }

        .filter-box {
            display: flex;
            width: 100%;

            .tv-filter {
                display: flex;
                justify-content: space-between;
                border: 2px solid #FFFFFF;
                border-radius: 10px;
                padding: 7px 25px;
                width: 17.5vw;
                cursor: pointer;


                &.selected {
                    border-color: #00DEFF;

                    .label-text {
                        color: #00DEFF;
                    }

                    .filter-icon {
                        color: #00DEFF;

                    }
                }

                .label-text {
                    display: flex;
                    align-items: center;
                    white-space: nowrap;

                }

                .filter-icon {
                    display: flex;
                    align-items: center;
                    margin-left: 20px;
                    cursor: pointer;
                    color: white;
                }
            }

            .tv-search {
                display: flex;
                align-items: center;
                border: 2px solid #FFFFFF;
                border-radius: 10px;
                padding: 10px;
                cursor: pointer;
                margin-left: 2%;
                color: white;
                // transition: all ease .3s;

                &.open {
                    width: 45%;
                    border-color: #00DEFF;
                    color: #00DEFF;

                    @include responsive(1400px) {
                        width: 46%;
                    }

                    #tv-search {
                        transition: all ease .5s;
                        width: 94%;
                    }

                    &.tvguide {
                        width: 25%;
                    }
                }

                #tv-search {
                    padding: 0;
                    border: none;
                    border-radius: 0;
                    height: auto;
                    font-family: SFUIText;
                    font-size: 16px;
                    width: 0;

                    &::placeholder {
                        /* Chrome, Firefox, Opera, Safari 10.1+ */
                        color: #00DEFF;
                        opacity: 1;
                        /* Firefox */
                    }

                    &:-ms-input-placeholder {
                        /* Internet Explorer 10-11 */
                        color: #00DEFF;
                    }

                    &::-ms-input-placeholder {
                        /* Microsoft Edge */
                        color: #00DEFF;
                    }
                }

            }

            .tv-guide-nav {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border: 2px solid white;
                border-radius: 10px;
                // padding: 15px 30px;
                // width: 18%;
                padding: 7px 25px;
                width: 17.5vw;
                cursor: pointer;
                margin-left: auto;
                color: white;

                @include responsive(1400px) {
                    width: 25%;
                }

                .icon {
                    margin-left: 20px;
                }

                .label-text {
                    white-space: nowrap;
                }
            }
        }

    }

    .filtered-result-text {
        width: 70%;
        font-family: SFUIText;
        cursor: pointer;
        font-size: 24px;
        color: #4D6175;
        margin-top: 20px;
    }

    .channel-container {
        display: flex;
        justify-content: space-between;
        width: 79.6vw;
        margin: auto;
        margin-top: -.78vw;
        transition: all ease .3s;

        @include responsive(1400px) {
            width: 75vw;
            margin-left: 12.5vw;
        }

        &.extend {
            width: 84vw;

            @include responsive(1400px) {
                width: 87vw;
            }
        }

        .channel-list-column {
            display: flex;
            position: relative;
            width: 23%;
            transition: all ease .3s;
            margin-top: 3.12vw;

            &.extend {
                width: 35vw;
            }
        }

        .channel-detail-column {
            display: flex;
            width: 77%;
            // height: calc(100vh - 100px);
            position: relative;
            padding-left: 25px;
            scrollbar-color: white transparent;

            &.extend {
                width: 48.66vw;
            }

            &.extend-l {
                width: 60.66vw;
            }

            .detail-wrapper {
                position: relative;
                width: 100%;

                .channel-detail-box {
                    width: 100%;
                    opacity: 1;
                    transition: all ease .5s;
                    padding-top: 3.12vw;

                    &.hide {
                        opacity: 0;
                    }
                }

            }
        }
    }
}


.channel-detail-column::-webkit-scrollbar {
    width: 8px;
}

/* Track */
.channel-detail-column::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
.channel-detail-column::-webkit-scrollbar-thumb {
    background: #fff;
}

/* Handle on hover */
.channel-detail-column::-webkit-scrollbar-thumb:hover {
    background: rgb(209, 209, 209);
}

.date-filter {
    position: relative;
    margin-left: 2%;
    margin-right: 2%;
}

.direction-button-group {
    display: flex;
    position: absolute;
    top: 15px;
    right: 0;
    z-index: 30;

    .icon {
        cursor: pointer;
        display: flex;
        margin-right: 20px;
    }
}