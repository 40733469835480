.wirecard-container {
    position: fixed;
    width: 80%;
    height: 85%;
    left: 10%;
    top: 7.5%;
    background-color: white;

    .close-wrapper {
        position: absolute;
        right: 0;
        top: 0;
    }

    .wirecard-frame {
        width: 100%;
        height: 100%;
    }
}