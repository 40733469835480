.channelguide-container {
    display: flex;
    flex-direction: column;
    position: relative;

    .filters-row {
        display: flex;
        margin: 20px 0;
        align-items: center;
        width: 100%;
        cursor: pointer;
        justify-content: space-between;
        // margin-left: 2%;

        .next-program-title {
            display: flex;
            align-items: center;
            font: normal normal normal 1.15vw SFUIText;
            text-align: right;
            color: white;


        }

        .filter {
            display: flex;
            position: relative;
            align-items: center;
            cursor: pointer;
            justify-content: space-between;
            border: 2px solid #FFFFFF;
            border-radius: 10px;
            padding: 5px 20px;
            opacity: 1;
            text-align: left;
            width: 200px;
            text-align: left;


            &.selected {
                border: 2px solid #00DEFF;

                .text,
                .icon {
                    color: #00DEFF;
                }

            }

            .text {
                font: normal normal normal 1vw SFUIText;
                color: white;
                text-align: right;

            }

            .icon {
                font-size: 22px;
                color: white;

            }

        }
    }

    .program-container {
        max-height: 500px;
        overflow-y: auto;
        padding-right: 5px;
        width: calc(100% + 5px);

        &::-webkit-scrollbar {
            width: 8px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: transparent;
            border-radius: 3px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #fff;
            border-radius: 3px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: rgb(209, 209, 209);
        }

    }


}