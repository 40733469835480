.search-container {
    padding: 70px 1.6vw;

    .sug-text {
        font-family: ProximaNovaBlack;
        font-size: 2vw;
        color: white;
        margin-left: 15px;
        margin-top: 3vw;
    }
}

.no-data-container {
    margin-top: 6vw;

    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: white;

        .text {
            font-family: SFUIText;
            font-size: 1.3vw;
            margin-bottom: 3.22vw;
        }

        .title {
            font-family: Swis721HvBT;
            font-size: 1.3vw;
            margin-bottom: 1.5vw;

        }

        .suggestion-item {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1.2vw 3.53vw;
            font-family: SFUITextLight;
            font-size: 1.3vw;
            // border: 1px solid #707070;
            border-radius: 2vw;
            background-color: rgba($color: #fff, $alpha: .06);
            margin-bottom: 1.1vw;
        }
    }
}

.keyword-row {
    display: flex;
    align-items: center;
    margin-top: 2vw;
    width: 100%;
    user-select: none;

    .title {
        font-family: SFUITextMedium;
        font-size: 1.3vw;
        color: white;
        white-space: nowrap;
        margin-right: 1.3vw;
    }

    .keyword-wrapper {
        display: flex;
        overflow-x: auto;
        scrollbar-width: none;

        .keyword-item {
            font-family: SFUITextSemiBold;
            font-size: .94vw;
            color: #4D6175;
            padding: .6vw 1.2vw;
            border-radius: 53px;
            border: 1px solid #4D6175;
            white-space: nowrap;
            margin-right: .94vw;
            cursor: pointer;
        }


        &::-webkit-scrollbar {
            width: 0;
            display: none;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: transparent;
            border-radius: 3px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #fff;
            border-radius: 3px;

        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: rgb(209, 209, 209);
        }
    }
}