@mixin login-button(
  $font-family,
  $background,
  $margin-top,
  $color,
  $width,
  $line-height,
  $height
) {
  font-family: $font-family;
  font-size: 16px;
  background: $background;
  text-align: center;
  width: $width;
  height: $height;
  line-height: $line-height;
  color: $color;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  margin-top: $margin-top;
}

@mixin login-text($font-family, $font-size, $height, $margin-top) {
  height: $height;
  margin-top: $margin-top;
  text-align: center;
  color: $white;
  font-size: $font-size;
  font-family: $font-family;
}

@mixin login-input($width, $height) {
  &:-webkit-autofill {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
  }
  width: $width;
  height: $height;
  border-radius: 2px;
  border: 2px solid #fff;
  background-color: transparent !important;
  font-family: SFUIText;
  font-size: 14px;
  padding: 11px 40px 11px 20px !important;
  &::placeholder {
    color: #fff;
  }
}

@mixin input-error($width) {
  color: red;
  display: flex;
  justify-content: left;
  width: $width;
  word-break: break-word;
  p {
    text-align: left;
  }
}
