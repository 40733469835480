.record-card {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: space-between;
    width: 100%;
    height: 8.84vw;
    padding: 8px;
    margin-bottom: 1.04vw;
    border: 2px solid #3D4E5A40;
    border-radius: 6px;
    background-color: #0a1624;
    opacity: 1;

    .record-poster-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 20%;
        
        &.pointer{
            cursor: pointer;
        }
        //cursor: pointer;

        &:hover {
            .record-gradient {
                opacity: 1;
            }
        }

        img {
            width: 100%;
            height: auto;
            border-radius: 15px;
            padding: .52vw;
        }

        .record-gradient {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($color: #0C1927, $alpha: .43);
            border-radius: 10px;
            opacity: 0;
            transition: all ease .3s;

            .record-play-button {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;
                background-color: rgba($color: white, $alpha: .8);
                border-radius: 50%;
                cursor: pointer;
            }
        }
    }
    
    

    .info-box {
        display: flex;
        align-items: left;
        justify-content: center;
        flex-direction: column;
        width: 70%;
        padding: 2.7vw;

        .row {
            font-family: SFUIText;
            font-size: .728vw;

            .title {
                color: rgba($color: #fff, $alpha: .9);
                font-size: 0.832vw;
            }

            .blue {
                color: #00DEFF;
                align-items: center;
            }

            .red {
                color: #E9253E;
                align-items: center;
            }

            .status {
                display: flex;
                align-items: center;
                margin-top: 1.3vw;

                span {
                    margin-right: 10px;
                }

                .red {
                    text-align: center;
                    align-items: center;
                }

                .blue {
                    text-align: center;
                    align-items: center;
                }
            }

            .info {
                display: flex;

                label {
                    color: white;

                }

                span {
                    color: #587086;
                    margin: 0 5px;
                }

                .date {
                    color: #4D6175;
                    ;
                }
            }
        }
    }

    .delete {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 10%;

        .delete-button {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 36px;
            height: 36px;
            background-color: #112134;
            border: 1px solid rgba($color: #FFFFFF, $alpha: 0.5);
            border-radius: 50%;
            color: white;
            cursor: pointer;
            z-index: 2;
        }


    }

}