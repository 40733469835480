.coverage{
    display:block;
    align-items: center;  
    text-align: center; 
    justify-content: center;

    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
   
   
  

   .channel-coverage{
     align-items: center;
     text-align: center;
     align-self:center;
     font-size: 1.976vw;
     color:#fff;
     font-family: SFUIText;
     margin-bottom: 2.184vw;
    
   }
   .coverage-phone{
       display:inline-flex;
       align-items: center;
       text-align: center;
       width: 20.28vw;
       height: 4.16vw;
       border: 2px solid #D5DC36;
       border-radius: 40px;
       opacity: 1;
       margin-bottom: 3.38vw;
       .phone{
           display: flex;
           font-size: 1.9vw; 
           color:#fff;
           font-weight: bold;
           font-family: SFUIText;
           padding-left: 4vw;
           padding-right: 4vw;
       } 
       .rtuk{
        display: flex;
        font-size: 1.9vw; 
        color:#fff;
        font-weight: bold;
        font-family: SFUIText;
        margin-left: 3vw; 
        margin-top: .5vw;
       }
    }
    .coverage-locking{
        display:inline-flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        width: 6.76vw;
        height: 6.552vw;
        border: 2px solid #D5DC36;
        border-radius: 63px;
        opacity: 1;
        margin-bottom: 2.184vw;
      
       .locking{
        display: flex;
        align-self: center;
        text-align: center;
        margin-left: 46px; 
        margin-right: 46px;
        
        margin-top: .5vw;
       }
    }
    .package-install{
        display:flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        font-size: 1.404vw;
        color:#fff;
        font-family: SFUIText;
        margin-bottom: 1.352vw;
    }
    .package-change{
        display:inline-block;
        align-items: center;
        text-align: center;   
        width: 37.232vw;
        height: 6.188vw;
        color:#7E858C;
        font-family: SFUIText;
        font-size: 1.2vw;
      
    }
    .package-control{
        display: inline-block;
        align-items: center;
        text-align: center;
        width: 24.752vw;
        height: 3.848vw;  
        color:#7E858C;
        font-family: SFUIText;
        font-size: 1.2vw;
       
    }
    .package-locking{
        display: inline-block;
        align-items: center;
        text-align: center;
        width: 20.904vw;
        height: 3.848vw;  
        color:#7E858C;
        font-family: SFUIText;
        font-size: 1.2vw;
       
    }
}