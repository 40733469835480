.modal-hover {
    position: absolute;
    // width: 500px;
    z-index: 10;
    padding: 0 15px;
    transition: transform ease .4s, opacity ease .2s;
    transform: scale(0);
    opacity: 0;

    &.scale {
        transform: scale(1);
        opacity: 1;

    }
}

.modal-preview-vod {
    height: min-content;
    box-shadow: 0px 5px 35px #000000FC;
    border-radius: 8px;

    .mini-vod-detail-container {
        display: flex;
        flex-direction: column;
        background-color: #0C1927;


        .image-wrapper {
            position: relative;
            width: 100%;

            .vod-preview-img {
                width: 100%;
                height: auto;
                // border-radius: 20px;
            }
        }

        .preview-info {
            max-height: 0;
            opacity: 0;
            transition: all ease .3s;
            padding: 0 25px;

            &.scale {
                max-height: 1000px;
                opacity: 1;
            }

            .mini-voddetail-row {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 20px 0;

                .description {
                    font-family: SFUIText;
                    font-size: 14px;
                    line-height: 26px;
                    color: white;
                    // white-space: wrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 2; // max nb lines to show
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                }
            }
        }
    }
}

.button-group {
    display: flex;


}

.inspect-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    background-color: #fff;
    font-family: SFUITextSemiBold;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    color: #0c1927;
    cursor: pointer;
    transition: all ease .3s;
    padding: 4px 0;
    width: 100%;

    &:hover {
        background-color: rgba($color: white, $alpha: .7);
    }


}