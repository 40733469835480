@mixin responsive($query) {
  @media (max-width: + $query) {
    @content;
  }
}

.banner-wrapper {
  position: relative;
  min-height: 300px;
  // top: -70px;

  .banner-item {
    width: 100%;
    position: relative;
    cursor: pointer;
    outline: none;

    .banner-poster {
      width: 100%;
      height: auto;
      background-size: center, center;
      transition: all ease 0.3s;
    }

    .gradient {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgb(12, 25, 39);
      background: linear-gradient(
        0deg,
        rgba(12, 25, 39, 1) 0%,
        rgba(12, 25, 39, 0) 20%
      );
      z-index: 15;
      top: 0;
      left: 0;
    }

    .transparent {
      position: absolute;
      width: 100%;
      height: 100%;
      // background: rgb(12, 25, 39, 0.63);
      z-index: 14;
      top: 0;
      left: 0;
    }

    .info-wrapper {
      position: absolute;
      // bottom: 20%;
      top: 40%;
      left: 1%;
      // width: 70%;
      margin-left: 15%;
      height: 50vh;
      display: flex;
      justify-content: flex-start;
      // align-items: center;
      z-index: 17;
      // background: transparent linear-gradient(180deg, #00000000 0%, #000000BD 40%, #000000 100%) 0% 0% no-repeat padding-box;

      @include responsive(1400px) {
        // height: 73%;
        // bottom: 14%;
        margin-left: 12.5%;
      }

      .info-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        // width: 60%;

        @include responsive(1400px) {
          width: 80%;
        }

        .title-wrapper {
          transition: all ease 0.3s;

          .channel-title,
          .title {
            font-size: 45px;
            font-family: ProximaNova;
            font-size: 80px;
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.9;
            letter-spacing: 2.13px;
            text-align: left;
            color: #fff;

            @include responsive(1400px) {
              font-size: 32px;
            }
          }

          .channel-title {
            font-size: 35px;
          }
        }

        .metadata-box {
          font-family: normal normal normal 45px/61px Open Sans;
          color: white;
          // opacity: 0;
          transition: all ease 0.3s;
          margin-bottom: 20px;

          .metadata-detail {
            .metadata-row {
              display: flex;
              font-size: 28px;
              margin-top: 20px;
              align-items: center;

              .year,
              .duration,
              .genre,
              .banner-rating {
                display: inline-flex;
                font-family: SFUIText;
                font-size: 1.1vw;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                letter-spacing: normal;
                text-align: left;
                color: #9b9b9b;
              }

              .banner-rating {
                font-size: 24px;
              }

              .description {
                opacity: 0.9;
                font-family: SFUIText;
                font-size: 1vw;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 2;
                letter-spacing: normal;
                text-align: left;
                color: #fff;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3; // max nb lines to show
                -webkit-box-orient: vertical;
              }

              .seperator {
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: #9b9b9b;
                margin: 0 10px;
              }
            }
          }
        }

        .play-button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 12.4vw;
          height: 3.12vw;
          border-radius: 2px;
          background-color: #fff;
          font-family: SFUIText;
          font-size: 1vw;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 2;
          letter-spacing: normal;
          text-align: left;
          color: #0c1927;
          margin-right: 19px;
          cursor: pointer;
          transition: all ease 0.3s;

          .icon {
            display: flex;
            align-items: center;
            color: #0c1927;
            margin-right: 25px;
            font-size: 20px;
          }

          &:hover {
            background-color: rgba($color: white, $alpha: 0.7);
          }
        }

        .price {
          font-size: 26px;
          letter-spacing: 0px;
          color: #cccccc;

          &.discount {
            text-decoration: line-through;
          }
        }

        .button {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          width: 3.64vw;
          height: 3.12vw;
          border-radius: 2px;
          background-color: rgba(255, 255, 255, 0.2);
          margin-right: 8.5px;

          &:hover {
            background-color: white;

            .icon {
              color: #0c1927;
            }
          }

          &.selected {
            background-color: white;
          }

          .icon {
            display: flex;
            font-size: 1.3vw;
            color: #d8d8d8;

            &.checked {
              color: #0c1927;
            }
          }

          label {
            font: normal normal normal 26px/36px Open Sans;
            letter-spacing: 0px;
            color: #cccccc;
            margin-left: 10px;
            cursor: pointer;

            @include responsive(1400px) {
              font: normal normal normal 16px/26px Open Sans;
            }
          }
        }
      }
    }

    .bottom-bant {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50px;
      background-color: rgba(33, 47, 50, 0.6);
      text-align: right;
    }
  }

  .next-prev-button {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    width: 64px;
    height: calc(100% - 50px);
    color: white;
    opacity: 0;
    z-index: -1;
    transition: all ease 0.3s;
    cursor: pointer;
  }

  .next {
    right: 0;
    height: calc(100% - 50px);
  }

  .prev {
    left: 0;
    height: calc(100% - 50px);
  }

  &:hover {
    .next,
    .prev {
      opacity: 1;
      z-index: 1;
    }
  }

  .slick-dots {
    position: absolute;
    bottom: 23%;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
  }

  .slick-dots li button:before {
    color: rgba($color: white, $alpha: 0.1);
    opacity: 1;
  }
}
