.record-container {
    // width: 85%;
    margin: auto;
    margin-top: 20px;

    .label-name {
        text-align: left;
        font: normal normal 600 23px/28px Segoe UI;
        letter-spacing: 0.37px;
        color: #FFFFFF;
        opacity: 1;
    }

    .record-grid {
        // display: flex;
        // flex-wrap: wrap;
        // padding-top: 31px;
    }
}



.record-item {
    // display: flex;
    margin: 5px;
    transition: all ease 0.3s;
    overflow: hidden;
    cursor: pointer;
    outline: none !important;
    position: relative;

    &:hover {
        transform: scale(1.1);


    }
}