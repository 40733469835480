@mixin responsive($query) {
    @media (max-width: + $query) {
        @content;
    }
}

.category-row {
    position: relative;
    width: 100%;
    margin: auto;
    margin-bottom: 50px;

    .category-wrapper {
        display: flex;
        justify-content: center;

        .category-item {
            display: flex;
            padding: 13px 40px;
            font-family: ProximaNovaBlack;
            font-size: 1.2vw;
            text-align: left;
            color: #fff;
            border-bottom: 2px solid rgb(200, 214, 221, 0.28);
            white-space: nowrap;
            cursor: pointer;

            &.selected {
                color: #00deff;
                border-bottom: 2px solid rgb(0, 222, 255);

            }
        }
    }

    @include responsive(1400px) {
        width: 75%;
    }
}