.payment-container {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 5.2vw;
    padding-top: 2.6vw;

    .box {
        display: flex;
        position: relative;
        width: 100%;

        .vod-poster {
            position: relative;
            width: 33%;

            img {
                width: 100%;
                height: auto;
            }

            .gradient {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: transparent linear-gradient(0deg, #0C1927 0%, #00000028 100%, #05B4C700 100%) 0% 0% no-repeat padding-box;
            }
        }

        .wrapper {
            width: 66%;
            margin-left: 5%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .payment-options-row {
                width: 100%;

                .payment-options {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;

                    .payment-option-box {

                        .payment-option {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: #0E1C2B;
                            cursor: pointer;
                            border: 1px solid rgba($color: #3D4E5A, $alpha: .25);
                            width: 9.6vw;
                            height: 7.8vw;


                            .icon {
                                display: flex;
                                font-size: 1.9vw;
                                color: white;
                            }
                        }

                        .text {
                            font-family: SFUITextLight;
                            font-size: 1.2vw;
                            color: white;
                            text-align: center;
                            margin-top: 1.3vw;
                        }
                    }
                }

                .promotion-option {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 3.33vw;
                    background-color: #0E1C2B;
                    border: 1px solid #3D4E5A3F;
                    margin-top: 1.87vw;
                    padding: 0 1.56vw;
                    cursor: pointer;

                    .icon {
                        color: white;
                        font-size: 1.2vw;
                    }

                    .text {
                        font-family: SFUITextLight;
                        font-size: 1.2vw;
                        color: white;
                        margin-left: 22px;
                    }
                }
            }
        }

    }
}

.payment-input-row {
    display: flex;
    flex-direction: column;
    width: 70%;
}


.payment-text {
    font-family: Swis721HvBT;
    font-size: 2.6vw;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 3vw;
}

.payment-confirm-text {
    font-family: SFUITextLight;
    font-size: 1.2vw;
    line-height: 1.7vw;
    color: white;
    text-align: center;

    span {
        font-family: SFUITextMedium;
    }
}

.flex-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.payment-container-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.confirm-button-box {
    display: flex;
    justify-content: center;
    width: 80%;
    margin-top: 4.16vw;
}



.close-row {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2.6vw;

    .close {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        background: #FFFFFF4D;
        border: 1px solid #FFFFFF80;
        border-radius: 50%;
        cursor: pointer;
    }
}