.cast-slider-wrapper {
    margin-top: 13px;
    position: relative;

    .cast-slider {
        width: 85%;

        .cast-link {
            text-decoration: none;

            .cast-poster-box {
                cursor: pointer;

                .cast-poster {
                    margin-right: 22px;
                    img {
                        outline: none;
                    }
                }

                .name {
                    font-family: SFUIText;
                    font-size: 12px;
                    color: #9B9B9B;
                    margin-top: 18px;
                    text-align: center;
                    margin-right: 22px;
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

        }
    }

    .cast-next {
        position: absolute;
        right: 0px;
        top: 0;
        width: 20%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
}