.vod-grid {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    justify-content: center;

    &.search-owerflow {
        overflow-y: auto;
        height: calc(100vh - 150px);
        overflow-x: hidden;
    }



    .home-grid-item {
        width: 14%;
        height: fit-content;
    }

    .filter-options-box {
        position: absolute;
        top: -10px;
        left: -20px;
        width: 30%;
        background: linear-gradient(90deg, black 30%, rgba(0, 0, 0, 0.700718) 76%, rgba(0, 0, 0, 0.400998) 88%, rgba(0, 0, 0, 0.00884104) 95%);
        padding: 20px 10px;
        height: calc(100vh - 190px);
        z-index: -1;
        opacity: 0;
        transition: all ease 0.3;

        ul li {
            margin: 0;
            padding: 0;
            text-indent: 0;
            list-style-type: none;
            color: $tivibuSoftBlueColor;
            padding: 15px 5px;
            font-size: 20px;
            display: flex;
            align-items: center;

            .selected-icon {
                width: 10px;
                height: 10px;
                background-color: white;
                border-radius: 50%;
                margin-right: 10px;
                visibility: hidden;

            }

            .selected {
                visibility: visible;
            }
        }
    }

    .show {
        z-index: 10;
        opacity: 1;
    }

    scrollbar-color: white transparent;

    &::-webkit-scrollbar {
        width: 3px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #fff;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: rgb(209, 209, 209);
    }
}


.grid-item {
    display: flex;
    // width: 25%;
    // height: fit-content;
}