.trailer-videojs-player .vjs-loading-spinner,
.trailer-videojs-player .vjs-loading-spinner {
    display: none;
}

.trailer-audio-button-container {
    position: absolute;
    display: flex;
    bottom: 4em;
    right: 2em;
    z-index: 2;

    // transition: all ease 0.3s;
    .audio-control-button {
        font-size: 3em;
        color: white !important;

    }
}