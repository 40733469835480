$tivibuSoftBlueColor: #49BED8;

@mixin responsive($query) {
    @media (max-width: + $query) {
        @content;
    }
}

.left {
    display: inline-flex;
    justify-content: left;
}

.right {
    display: inline-flex;
    justify-content: right;
    height: 100%;
}

.category {
    display: flex;
    align-items: center;
    height: 100%;
    color: white;
    transition: all ease 0.5s;
    cursor: pointer;
    padding: 7px 25px;
    white-space: nowrap;

    label {
        font-family: SFUIText;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.31;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
        cursor: pointer;

    }

    @include responsive(1400px) {
        padding: 7px 12px;

    }
}

.centered {
    justify-content: center;
}

.is-active {

    label {
        color: $tivibuSoftBlueColor;

    }

}

.movie {
    background-color: #0c1927;
}

.watch-tv-selected {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 113px;
    height: 45px;
    background: transparent linear-gradient(262deg, #5DADB7 0%, #DFAB75 25%, #FC983F 45%, #E62F93 78%, #BF0CCB 100%) 0% 0% no-repeat padding-box;
    border-radius: 23px;
    font-family: SFUIText;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}