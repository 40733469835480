.tvguide-row {
  margin-bottom: 1vw;

  .multi-program-item-box {
    display: inline-block;
    position: relative;
    padding: 10px 20px;
    width: 26vw;
    height: 5.2vw;
    background-color: #0e1e2f;
    margin-right: 12px;
    border: 1px solid #3d4e5a40;
    border-radius: 4px;
    // min-width: 175px;

    .program-item-inner {
      display: flex;
      align-items: center;
      height: 100%;

      .program-info {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        .inner-info {
          display: flex;
          flex-direction: column;
          // align-items: center;
          padding-right: 10px;
          width: 100%;

          .title {
            width: 13vw;
            font-family: SFUIText;
            font-size: 14px;
            color: white;
            margin-bottom: 8px;
            max-width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            cursor: pointer;
            user-select: none;

            &:hover {
              color: rgba($color: white, $alpha: 0.7);
            }
          }

          .genre-date {
            display: flex;
            font-family: SFUIText;
            font-size: 13px;
            color: #587086;
            user-select: none;
            max-width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            user-select: none;
          }
        }

        .inner-rating {
          display: flex;
          font-size: 1.67vw;
          color: white;
          margin-right: 20px;

          .info-button {
            display: flex;
            justify-content: center;
            align-items: center;
            background: #112134 0% 0% no-repeat padding-box;
            border: 0.7175999283790588px solid #3d4e5a80;
            opacity: 1;
            width: 1.67vw;
            height: 1.67vw;
            border-radius: 50%;
            cursor: pointer;
            margin-right: 10px;
          }
        }
      }

      .program-poster {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 6vw;
        user-select: none;
        position: relative;

        img {
          max-width: fit-content;
          width: 100%;
          height: auto;
          max-height: 5vw;
          //max-height: 100%;
          border-radius: 4px;
        }

        .live-program-indicator {
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: absolute;
          top: 7px;
          left: 5px;
          width: 40px;
          height: 15px;
          background: #f71100;
          border-radius: 20px;
          color: white;
          padding: 0 5px;

          .text {
            font-size: 10px;
          }

          .icon {
            font-size: 4px;
          }
        }
      }
    }

    .current-progress {
      position: absolute;
      width: 25.85vw;
      height: 1px;
      background-color: rgba(255, 255, 255, 0.4);
      bottom: -1px;
      left: 0;

      .progress {
        height: 100%;
        width: 0;
        background-color: #00deff;
      }
    }
  }
}
