.package-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 7.8vw;

    .user-package-item {
        display: flex;
        align-items: center;
        background-color: #0e1c2b;
        border: 1px solid #3d4e5a40;
        padding: 1vw;
        width: 100%;

        .poster-wrapper {
            width: 16vw;

            img {
                width: 100%;
                height: auto;
            }
        }

        .package-info {
            width: fit-content;
            padding: 2.1vw 2.6vw;

            .label {
                font-family: SFUIText;
                font-size: 1.15vw;
                color: rgba($color: #fff, $alpha: .9);
                margin-bottom: 1.56vw;
            }

            .title {
                font-family: SFUIText;
                font-size: .94vw;
                color: #00DEFF;
            }

            .description {
                font-family: SFUITextLight;
                font-size: .94vw;
                line-height: 1.3vw;
                color: white;
                margin-bottom: 1.25vw;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2; // max nb lines to show
                -webkit-box-orient: vertical;

            }

            .price {
                font-family: SFUIText;
                font-size: 1.5vw;
                color: #FFF277;

                span {
                    font-size: .84vw;
                }
            }
        }
    }

    .seperator {
        width: 100%;
        height: 1px;
        background-color: #3D4E5A40;
        margin: 2.6vw 0;
    }

    .package-list {
        display: flex;
        flex-wrap: wrap;

        .package-item {
            width: 50%;

            margin-bottom: 1.6vw;

            .package-box {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background-color: #0e1c2b;
                border: 1px solid #3d4e5a40;
                padding: 1vw;

                .poster-wrapper {
                    width: 16vw;

                    img {
                        width: 100%;
                        height: auto;
                    }
                }

                .info {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 0 4vw;
                    margin-top: 2.6vw;
                    margin-bottom: 3.32vw;

                    .name {
                        font-family: SFUIText;
                        font-size: .94vw;
                        line-height: 1.3vw;
                        color: rgba($color: #00DEFF, $alpha: .9);
                        margin-bottom: .5vw;
                        text-align: center;
                    }

                    .desc {
                        font-family: SFUITextLight;
                        font-size: .94vw;
                        line-height: 1.3vw;
                        text-align: center;
                        color: rgba($color: #fff, $alpha: .9);
                        margin-bottom: 1.25vw;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2; // max nb lines to show
                        -webkit-box-orient: vertical;
                    }

                    .button {
                        display: flex;
                        font-family: SFUITextBold;
                        font-size: .83vw;
                        align-items: center;
                        background-color: #D5DC36;
                        height: 3vw;
                        padding: 0 1.5vw;
                        border-radius: 4px;
                        color: #0C1927;
                    }
                }
            }

        }
    }
}