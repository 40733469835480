@mixin responsive($query) {
  @media (max-width: + $query) {
    @content;
  }
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  grid-gap: 30px;
}

.settings-container {
  background: #0c1927 0% 0% no-repeat padding-box;
  margin: auto;
  margin-top: 120px;
  width: 70%;

  @include responsive(1400px) {
    width: 75%;
  }

  .settings-icon {
    display: flex;
    align-items: center;
    margin-left: 1.56vw;
    margin-bottom: 4.16vw;
    cursor: pointer;
    font-size: 1.25vw;

    label {
      font-family: SFUIText;
      letter-spacing: 0px;
      color: #ffffff;
      margin-inline: 2.9vw;
      cursor: pointer;
    }

    .icon-settings {
      display: flex;
      margin-left: 30px;
      color: #ffffff;
      font-family: SFUIText;
      letter-spacing: 0px;
      opacity: 1;
    }
  }

  .list1 {
    margin-top: 30px;
    display: flex;
    width: 50%;
    grid-gap: 30px;

    li {
      display: flex;
      align-items: center;
      background-color: #0a1624;
      width: 870px;
      height: 265px;
      border: 1px solid #3d4e5a40;
      margin-left: 100px;
      opacity: 1;
      width: 80%;
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
    margin-top: 1.9vw;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 4.7vw;
      background-color: #0a1624;
      border: 1px solid #3d4e5a40;
      opacity: 1;
      padding: 0 2.6vw;
      margin-bottom: 1.56vw;

      label {
        font-family: SFUIText;
        font-size: .83vw;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
      }

      .span {
        display: flex;
        align-items: center;
        font-family: SFUIText;
        font-size: .83vw;
        letter-spacing: 0px;
        color: #4d6175;
        opacity: 1;
      }
    }

    .channel-container {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #0A1624;
      opacity: 0;
      z-index: -1;
      transition: all ease 0.3s;
      margin-top: -60px;
      margin-left: 250px;
    }
  }

  .settings-box-wrapper {
    justify-content: space-between;

    .settings-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 22%;
      height: 22%;
      background: #0a1624;
      border: 1px solid #3d4e5a40;
      padding: 1.8vw;
      margin-bottom: 1.6vw;

      .icon {
        text-align: center;
        margin-bottom: 3.6vw;
      }

      .settings-title {
        border-radius: 4px;
        opacity: 1;
        color: #ffffff;
        text-align: center;
        font-family: SFUIText;
        font-size: 16px;
      }
    }

    .settings-box1 {
      width: 23%;
    }

    .settings-box2 {
      width: 23%;
    }
  }

  .filters-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #0A1624;
    opacity: 0;
    z-index: -1;
    transition: all ease 0.3s;
    margin-top: -30px;
    margin-left: 250px;
  }

  .filter-containers {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #0A1624;
    opacity: 0;
    z-index: -1;
    transition: all ease 0.3s;
    margin-top: -30px;
  }

}

.filters-container.open {
  opacity: 1;
  z-index: 10;

}

.filter-containers.open {
  opacity: 1;
  z-index: 10;

}



.parentel-settings-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: #0a1624;
  width: 100%;
  height: 5.2vw;
  border: 1px solid #3d4e5a40;
  opacity: 1;
  margin-bottom: 1.56vw;
  padding: 0 3.12vw;
  border-radius: 4px;

  .description {
    font-family: SFUIText;
    font-size: .84vw;
    letter-spacing: 0px;
    color: #cccccc;
  }

  .icon {
    display: none;

    &.show {
      display: flex;
    }
  }

}

.parental-control-container {
  display: flex;
  justify-content: space-around;
  margin: auto;

  .parentalcontrol-card-wrapper {
    width: 20%;
    height: 13.8vw;
    margin-bottom: 1.8vw;

    .parentalcontrol-card {
      width: 100%;
      height: 100%;
      background-color: #0a1624;
      border: 1px solid #3d4e5a40;
      padding: 1.25vw;
      padding-bottom: 2.5vw;
      cursor: pointer;

      .parentalcontrol-card-body {
        display: flex;
        flex-direction: column;
      }

    }
  }
}


.dotsvg {
  height: 27px;
  width: 100%;
  align-items: center;

  .icon {
    font-size: 1.35vw;
    float: right;
  }
}

.parentalcontrol-card-icon {
  margin-bottom: 1vw;
  text-align: center;
  font-size: 4.8vw;
  color: white;
}

.parentalcontrol-card-text {
  margin-top: 0;
  margin-bottom: 1rem;
  text-align: center;
  font-size: .94vw;
  color: white;
}

.history-row {
  display: flex;
  align-items: center;
  padding-bottom: 50px;
  width: 100%;

  .settings-select-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 2px solid #FFFFFF;
    border-radius: 10px;
    padding: 10px;
    opacity: 1;
    margin-right: 30px;

    .title-filter-box {
      display: flex;
      margin-left: 30px;
      font-family: SFUIText;
      font-size: 14px;


      .filter-icon {
        font-size: 20px;
        color: white;
        cursor: pointer;
        margin-right: 20px;
      }
    }
  }
}

.list-history {
  margin-top: 30px;
  display: flex;
  width: 100%;
  flex-direction: column;
  grid-gap: 30px;

  li {
    display: flex;
    align-items: center;
    background-color: #0a1624;
    width: 1170px;
    height: 170px;
    border: 1px solid #3d4e5a40;
    opacity: 1;
    width: 100%;


  }
}

.record-info-box {

  label {
    font-family: SFUIText;
    font: size 18px;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    padding: 9px;

  }

  .info-row {
    display: flex;
    align-items: center;

    .span {
      display: flex;
      align-items: center;
      font-family: SFUIText;
      font-size: 16px;
      letter-spacing: 0px;
      color: #4d6175;
      opacity: 1;
      padding: 9px;
    }

    .dot {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: #4d6175;
      margin: 0 10px;
    }
  }


}

.list-suggestion {
  display: flex;
  align-items: center;
  margin-top: 30px;
  display: flex;
  width: 100%;
  flex-direction: column;
  grid-gap: 30px;

  .message {
    background-color: #0a1624;
    height: 21vw;
    border: 0px solid #0a1624;
    border-radius: 8px;
    width: 100%;
    font-family: SFUIText;
    font-size: 1vw;
    letter-spacing: 0px;
    color: #FFFF;
    opacity: 1;
    padding: 38px;
    --darkreader-inline-color: #FFFF;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      opacity: 1;
      font-family: SFUIText;
      font-size: 1vw;
      /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: white;
      font-family: SFUIText;
      font-size: 1vw;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: white;
      font-family: SFUIText;
      font-size: 1vw;
    }
  }

  .button {
    width: 146px;
    height: 65px;
    background: #D5DC36 0% 0% no-repeat padding-box;
    border: 2px solid #D5DC36;
    border-radius: 4px;
    opacity: 1;
    padding-left: 45px;
    padding-right: 45px;
    font-family: SFUIText;
    font-size: 16px;

  }
}

.record {
  display: flex;
  align-items: center;
  background-color: #0a1624;
  width: 100%;
  height: 8.9vw;
  border: 1px solid #3d4e5a40;
  opacity: 1;


  .serie-record {
    font-family: SFUIText;
    font-size: .8vw;
    text-align: left;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin-left: 6.76vw;
    margin-right: 3.64vw;
    width: 35%;
  }

  .quota {
    font-family: SFUIText;
    font-size: .70vw;
    text-align: left;
    letter-spacing: 0px;
    color: #587086;
    opacity: 1;
  }

  .icon {
    // width: 6.2vw;
    width: 14%;
  }

  .record-serie {
    font-family: SFUIText;
    font-size: .8vw;
    text-align: right;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin-right: 6.76vw;
    margin-left: 3.64vw;
    width: 35%;
  }

  .expired {
    font-family: SFUIText;
    font-size: .70vw;
    text-align: right;
    letter-spacing: 0px;
    color: #587086;
    opacity: 1;
  }
}

.filter-option {
  text-align: center;
  margin-top: 2.08vw;
  font-family: SFUIText;
  font-size: 1.144vw;
  padding: 2.184vw 2.184vw;

  .yellow {
    border-bottom: 2px solid rgb(200, 214, 221, 0.28);
    color: #ffffff;
    font-weight: bold;
    padding: 2.184vw;
    cursor: pointer;

    &.selected {
      color: #00deff;
      border-bottom: 2px solid rgb(0, 222, 255);
    }
  }

  .blue {
    border-bottom: 2px solid rgb(200, 214, 221, 0.28);
    color: #ffffff;
    font-weight: bold;
    padding: 2.184vw;
    cursor:pointer;

    &.selected {
      color: #00deff;
      border-bottom: 2px solid rgb(0, 222, 255);
      cursor:pointer;
    }
  }
}


.line {
  display: flex;
  align-items: center;
  background-color: #0e1c2b;
  border: 0.1px solid #3d4e5a40;
  width: 900px;
  height: 0px;
  margin-left: 100px;
  opacity: 1;
  width: 80%;
  margin-bottom: 50px;
}

.poster-img {
  font-family: SFUIText;
  font-size: 14px;
  text-align: left;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  padding-left: 20px;
  padding-right: 52px;
  width: 320px;
  height: 250px;

}

.row {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 30px;

  .tivibugo {
    display: flex;
    align-items: center;
    background-color: #0e1c2b;
    width: 38.5%;
    height: 578px;
    border: 1px solid #3d4e5a40;
    margin-left: 100px;
    opacity: 1;
    margin-bottom: 50px;
    display: inline-block;

    .gopackage {
      text-align: center;
      padding: 20px;
      width: 100%;
      height: auto;
      padding-bottom: 28px;
    }

    .packgo {
      padding-left: 80px;
      padding-right: 80px;
      text-align: center;
      color: #00DEFF;
      display: inline-block;
      font-size: 18px;
    }

    .packgoo {
      padding-left: 80px;
      padding-right: 80px;
      text-align: center;
      color: #FFFF;
      padding-bottom: 65px;
      font-size: 18px;
    }

    .button {
      text-align: center;
      width: 160px;
      height: 65px;
      background-color: #D5DC36;
      color: black;
      padding-right: 40px;
      padding-left: 45px;
      border-radius: 4px;
      font-family: SFUIText;
      font-size: 16px;
    }
  }

  .tivibuhouse {
    display: flex;
    align-items: center;
    background-color: #0e1c2b;
    width: 38.5%;
    height: 578px;
    border: 1px solid #3d4e5a40;
    opacity: 1;
    margin-bottom: 50px;
    display: inline-block;

    .housepackage {
      text-align: center;
      padding: 20px;
      width: 100%;
      height: auto;
      padding-bottom: 28px;
    }

    .packhouse {
      padding-left: 60px;
      padding-right: 60px;
      text-align: center;
      color: #00DEFF;
      display: inline-block;
      font-size: 18px;
    }

    .packhousee {
      padding-left: 60px;
      padding-right: 60px;
      text-align: center;
      color: #FFFF;
      padding-bottom: 65px;
      font-size: 18px;
    }

    .button {
      text-align: center;
      width: 160px;
      height: 65px;
      background-color: #D5DC36;
      color: black;
      padding-right: 40px;
      padding-left: 45px;
      border-radius: 4px;
      font-family: SFUIText;
      font-size: 16px;
    }
  }
}

.favlockchannel-list-item {
  display: flex;
  width: 100%;
  height: 5.2vw;
  align-items: center;
  justify-content: center;
  position: relative;
  background: #0A1624;
  border: 1px solid #3D4E5A40;
  border-radius: 4px;

  .favlockchannel-logo {
    display: flex;
    align-items: center;
    width: 20%;
    height: auto;
    padding: 10px;

    @include responsive(1400px) {
      padding: 5px;
    }

    .logo {
      height: auto;
      width: 100%;
      padding: 40px;
    }
  }


  .favlockchannel-info {
    text-align: left;
    width: 100%;
    padding: 10px;

    @include responsive(1400px) {
      padding: 5px;
    }

    .favlockchannel-name {
      font-family: SFUIText;
      text-align: left;
      letter-spacing: 0px;
      color: #FFFFFF;
      font-size: .84vw;
      opacity: 1;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-bottom: 5px;
    }
  }

  .icons {
    display: flex;
    width: 15%;
    height: 45%;

    @include responsive(1400px) {
      padding: 5px;
    }

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #112134 0% 0% no-repeat padding-box;
      border: 1px solid #3D4E5A80;
      border-radius: 50%;
      width: 2.45vw;
      height: 2.45vw;
      transition: all ease .3s;
      color: white;
      font-size: .8vw;
      cursor: pointer;

      &:hover {
        color: #0C1927 !important;
        background-color: white;
      }

      &.fill-red {
        color: red;
      }

      &.fill-yellow {
        color: #D5DC36;
      }
    }
  }
}

.circular-progress {
  font-family: Swis721HvBT;
  color: white;

  span {
    font-size: 1vw;
  }

  label {
    font-size: 2vw;
  }
}

.record-items-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.9vw;
  width: 100%;
  animation-duration: .3s;
  animation-name: openWrapper;
  opacity: 1;
  margin-bottom: 6vw;
}

.record-series {
  display: flex;
  align-items: center;
  background: #0A1624;
  width: 100%;
  height: 5.2vw;
  margin-bottom: 2.34vw;

  .info-box {
    display: flex;
    align-items: left;
    justify-content: center;
    flex-direction: column;
    width: 70%;
    padding: 2.7vw;

    .row {
      font-family: SFUIText;
      font-size: .728vw;

      .title {
        color: white;
        font-size: 0.832vw;
      }
    }
  }

}

@keyframes openWrapper {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


.select-box-row {
  display: flex;
}

.settings-select-box {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-width: 13vw;
  width: fit-content;
  padding: 0 15px;
  height: 2.9vw;
  border: 1px solid white;
  border-radius: 4px;
  font-family: SFUIText;
  font-size: .84vw;
  color: white;
  margin-right: 19px;
  cursor: pointer;
  transition: all ease .3s;

  .icon {
    display: flex;
    align-items: center;
    color: white;
    margin-left: 25px;
    font-size: 20px;
  }

  .arrow-down {
    width: 0;
    height: 0;
    border: 6px solid;
    border-color: white transparent transparent transparent;
    border-bottom: 0px solid;


  }

  .transparent {
    border-color: transparent !important;
  }

  &.opened {
    .arrow-down {
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid white;
      border-top: 0px solid;
    }
  }

  .options {
    display: none;
    position: absolute;
    top: 3.5vw;
    left: 0;
    z-index: 10;
    width: 100%;
    border-radius: 4px;

    &.show {
      display: flex;
      flex-direction: column;
    }

    .option {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 2.9vw;
      background-color: #0C1927;
      border: 1px solid white;
      border-top: transparent;
      font-family: SFUIText;
      font-size: .84vw;
      color: white;

      &.selected {
        background-color: white;
        color: #0C1927;
      }
    }
  }

}

.row-logout {
  display: flex;
  justify-content: center;
  padding-right: 3.6vw;
}