@mixin responsive($query) {
    @media (max-width: + $query) {
        @content;
    }
}


.vod-detail-container {
    display: flex;
    // align-items: end;
    position: relative;
    justify-content: center;
    scroll-behavior: smooth;
    background-color: #0C1927;
    border-radius: 8px;

    .vod-detail-box {
        width: 100%;
        height: 100vh;
        position: absolute;
        color: whitesmoke;
        position: relative;
        overflow-y: auto;
        border-radius: 8px;

        .poster-wrapper {
            width: 100%;
            height: auto;
            position: relative;
            min-height: 500px;

            .close {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 36px;
                height: 36px;
                background: rgba($color: #FFFFFF, $alpha: 0.3);
                border: 1px solid rgba($color: #FFFFFF, $alpha: 0.5);
                border-radius: 50%;
                cursor: pointer;
                position: absolute;
                top: 20px;
                right: 20px;
                z-index: 2;
            }

            .back-voddetail {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 36px;
                height: 36px;
                background: rgba($color: #FFFFFF, $alpha: 0.3);
                border: 1px solid rgba($color: #FFFFFF, $alpha: 0.5);
                border-radius: 50%;
                cursor: pointer;
                position: absolute;
                top: 20px;
                left: 20px;
                z-index: 2;
            }

            img {
                width: 100%;
                height: auto;
            }

            .gradient {
                position: absolute;
                top: 0;
                right: 0;
                width: 100%;
                height: 100%;
                // background: linear-gradient(0deg, rgba(12, 25, 39, 1) 16%, rgba(0, 0, 0, 0.16) 80%, rgba(5, 180, 199, 0) 100%);
                background: linear-gradient(180deg, rgba(0, 0, 0, 0.16290266106442575) 0%, rgba(12, 25, 39, 1) 84%);

                z-index: 1;
            }
        }

        scrollbar-color: white transparent;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            width: 0px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: transparent;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            // background: #fff;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            // background: rgb(209, 209, 209);
        }
    }

    .vod-detail-back-icon {
        cursor: pointer;
        color: white;
        top: 15px;
        margin-right: 10px;
        margin-top: 15px;
        opacity: 0;
        z-index: -1;

        &.show {
            opacity: 1;
            z-index: 5;
        }
    }
}


.continue-label {
    display: flex;
    align-items: center;
    font: normal normal normal 26px/36px Open Sans;
    letter-spacing: 0px;
    color: #CCCCCC;
    margin-left: 15px;
}

.vod-detail-button-wrapper {
    display: flex;
    align-items: center;
}

.vod-detailcontainer-absolute {
    z-index: 2;
    position: absolute;
    // bottom: 0;
    left: 0;
    padding: 0 48px;
    width: 100%;

    // @include responsive(1600px) {
    //     bottom: -70px;
    // }
}

.episode-text {
    font-family: SFUITextBold;
    font-size: 24px;
    color: white;
}

.vod-detail-play-button {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 130px;
    height: 55px;
    padding: 0 10px;
    border-radius: 4px;
    background-color: #fff;
    font-family: SFUIText;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #0c1927;
    margin-right: 19px;
    cursor: pointer;
    transition: all ease .3s;

    .icon {
        display: flex;
        align-items: center;
        color: #0c1927;
        margin-right: 25px;
        font-size: 20px;
    }

    &:hover {
        background-color: rgba($color: white, $alpha: .7);
    }

}

.vod-detail-select-box {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 150px;
    height: 55px;
    border: 1px solid white;
    border-radius: 4px;
    font-family: SFUIText;
    font-size: 16px;
    color: white;
    margin-right: 19px;
    cursor: pointer;
    transition: all ease .3s;

    .icon {
        display: flex;
        align-items: center;
        color: white;
        margin-left: 25px;
        font-size: 20px;
    }

    .arrow-down {
        width: 0;
        height: 0;
        border: 6px solid;
        border-color: white transparent transparent transparent;
        border-bottom: 0px solid;


    }

    .transparent {
        border-color: transparent !important;
    }

    &.opened {
        .arrow-down {
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 6px solid white;
            border-top: 0px solid;
        }
    }

    .options {
        display: none;
        position: absolute;
        top: 55px;
        left: 0;

        &.show {
            display: flex;
            flex-direction: column;
        }

        .option {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 150px;
            height: 55px;
            border-radius: 4px;
            background-color: #fff;
            font-family: SFUIText;
            font-size: 16px;
            margin-top: 1px;
            color: #0C1927;
        }
    }

}

.vod-detail-rent-button {
    display: flex;
    justify-content: center;
    align-items: center;
    // width: 150px;
    padding: 0 15px;
    height: 55px;
    font-family: SFUITextSemibold;
    font-size: 16px;
    background: #FFFFFF33 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    cursor: pointer;
    transition: all ease .3s;
    margin-right: 15px;
    color: white;

    .bundle-box {
        margin-left: 20px;
        display: flex;
        align-items: center;
        color: #00DEFF;

        span {
            display: flex;
            margin-right: 10px;
        }

        label {
            cursor: pointer;
        }
    }

    .icon {
        display: flex;
        align-items: center;
        margin-right: 25px;
        font-size: 20px;
    }

    &:hover {
        background-color: white;
        color: #0C1927;
    }
}

.vod-detail-row {
    margin-bottom: 30px;

    .synopsis-box {
        font-family: SFUITextSemiBold;
        font-size: 16px;
        line-height: 26px;
        color: white;
    }
}

.metadata-container {
    // margin: 20px 48px;
    padding: 35px 32px;
    background-color: #060F19;

    // @include responsive(1600px) {
    //     margin-top: 90px;

    // }

    .title {
        font-family: SFUIText;
        font-size: 24px;
        line-height: 30px;
    }

    .vod-box {
        display: flex;
        margin-top: 30px;

        .vertical-poster {
            width: 125px;

            img {
                width: 100%;
                height: auto;
                border-radius: 10px;
            }
        }

        .info-box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-inline: 40px;

            .title {
                font-family: SFUIText;
                font-size: 18px;
                color: white;
            }

            .org-title {
                font-family: SFUIText;
                font-size: 16px;
                color: #9B9B9B;
                margin: 10px 0;
            }

            .info {
                display: flex;

                label {
                    font-family: SFUIText;
                    font-size: 14px;
                    line-height: 30px;
                    color: #9B9B9B;
                }

                span {
                    font-family: SFUIText;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 30px;
                    color: white;
                    margin-left: 5px;

                    .date {
                        display: flex;

                        .tire {
                            margin: 0 5px;
                        }
                    }

                }


            }
        }
    }

    .director-poster-box {
        width: 14%;
        margin-top: 15px;
        cursor: pointer;

        .director-name {
            font-family: SFUIText;
            font-size: 12px;
            color: #9B9B9B;
            margin-top: 18px;
            text-align: center;
        }
    }

}


.episode-bookmark-progress {
    position: absolute;
    width: calc(100%);
    height: 1px;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.4);


    .progress {
        height: 100%;
        width: 0;
        background-color: #00deff;
    }
}
