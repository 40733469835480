.ppv-payment-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
}



.flex-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.confirm-button-box {
    display: flex;
    justify-content: center;
}


.btn-send-gray-tr {
    background-color: rgba(255, 255, 255, 0.25);
    color: white;
}

.btn-send-gray {
    background-color: #808080;
    transition: all ease 0.3s;
    color: white;
    transition: all ease 0.3s;
}

.payment-options-box {

    width: 100%;



    .payment-options {
        display: flex;
        width: 100%;

        .payment-option {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #0E1C2B;
            cursor: pointer;
            border: 1px solid rgba($color: #3D4E5A, $alpha: .25);
            width: 185px;
            height: 150px;

            .text {
                font: normal normal normal 14px/19px Open Sans;
                color: #FFFFFF;
                text-align: center;
            }

            .icon {
                font-size: 30px;
                color: #999;
                margin-top: auto;
                transition: all ease 0.3s;


            }

            &:hover {
                .icon {
                    color: white;
                }
            }
        }
    }
}