$genreTextColor: #49BED8;

.genre-box {
    padding: 15px 0;
    cursor: pointer;
    direction: ltr;
    // min-width: 40%;

    .genre-text {
        position: relative;
        font-family: SFUIText;
        color: white;
        font-size: 16px;
        white-space: nowrap;

    }

    .selected-genre {
        color: #00DEFF;
    }
}