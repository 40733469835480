.progam-carousel-wrapper {
    display: flex;

    .channel-logo {
        display: flex;
        width: 7%;
        align-items: center;
        justify-content: center;
        height: 79px;
        background-color: #273b48;
        cursor: pointer;

        img {
            width: auto;
            height: 100%;
        }
    }

    .channel-logo.tr {
        background-color: transparent;
    }

    .program-carousel {
        // display: inline-flex;
        width: 93%;
    }
}

.program-item {
    display: flex;
    background-color: #3d505b;

    .program-info-box {
        display: flex;
        flex-direction: column;
        // flex: 4;
        padding: 10px 20px;
        width: 60%;

        .program-name {
            font: normal normal normal 24px/32px Segoe UI;
            color: white;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
        }

        .program-time {
            display: flex;
            align-items: center;
            font: normal normal normal 20px/27px Open Sans;
            color: #808080;

            .program-genre {
                width: 50%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .time-box {
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: end;
            }
        }
    }

    &.tr {
        background-color: transparent;
    }

    .program-rating-box {
        // flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;

        color: #999999;
        font-size: 40px;
        // background-color: #273b47;
    }

    .program-rating-box.tr {
        background-color: transparent;
    }
}

.current-program-item {
    display: flex;
    flex-direction: column;
    height: 79px;
    background-color: #273b48;

    .info-wrapper {
        display: flex;
        align-items: center;
        height: 70px;

        .seperator {
            width: 1px;
            height: 60px;
            background-color: #767676;
        }

        .info-box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 15px;

            .channel-name {
                font: normal normal normal 24px/32px Segoe UI;
                letter-spacing: -0.57px;
                color: #A1A1A1;
            }

            .program-name {
                font: normal normal 300 20px/27px Segoe UI;
                letter-spacing: -0.48px;
                color: #FFFFFF;
                cursor: pointer;
            }
        }
    }

    .progress {
        height: 2px;
        width: 70%;
        background-color: $softBlue;
        margin-top: auto;
        margin-bottom: 3px;
    }
}

.current-program-item.tr {
    background-color: transparent;
}