@mixin responsive($query) {
  @media (max-width: + $query) {
    @content;
  }
}

.navbar {
  display: flex;
  width: 100%;
  height: 70px;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0px;
  z-index: 50;
  transition: all ease 0.3s;

  &.amir {
    background-color: #0c1927;
  }

  &.top {
    // background: transparent linear-gradient(180deg, #000000 0%, #00000080 44%, #00000000 100%) 0% 0% no-repeat padding-box;
  }

  .navbar-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 90px;

    .menu-item-wrapper {
      display: flex;
      align-items: center;
    }

    .right {
      display: inline-flex;
      justify-content: right;
      width: 100%;
    }

    .buttons-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;
    }
  }
}

.settings-button {
  display: flex;
  align-items: center;
  height: 100%;
  transition: all ease 0.3s;
  cursor: pointer;
}

.setting-icon {
  display: flex;
  transition: all ease 0.3s;
  color: #49bed8;
  font-size: 40px;

  &:hover {
    color: white;
  }

  @include responsive(1400px) {
    font-size: 30px;
  }
}

.main-logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-right: 55px;
  cursor: pointer;

  @include responsive(1400px) {
    margin-right: 35px;
  }
}

.profile-logo-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  width: 100%;
  cursor: pointer;
  height: auto;

  .wrapper {
    display: flex;
    //display: -webkit-box;
    border-radius: 50%;
    border: 3px solid transparent;
    transition: all ease 0.3s;
    width: 56px;
    height: 56px;

    @include responsive(1400px) {
      width: 41px;
      height: 41px;
    }

    .profile-logo {
      width: 50px;
      height: auto;
      border-radius: 50%;

      @include responsive(1400px) {
        width: 35px;
      }
    }
  }

  .profile-change {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 15px;
    margin-right: 15px;

    .profile-name {
      font-family: SFUIText;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.31;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
      white-space: nowrap;
    }
  }

  .settings-options-box {
    display: none;
    position: absolute;
    top: 75px;
    right: 0;
    width: 200px;
    background-color: #0c1927;
    border: 1px solid #00deff;
    opacity: 0;
    padding: 25px;
    box-shadow: 0px 5px 35px #000000fc;

    &.show {
      display: block;
      animation-name: customOpacity;
      animation-duration: 0.3s;
      animation-delay: 0.1s;
      animation-fill-mode: forwards;
    }

    .profile-option-item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .profile-img-wrapper {
        display: flex;
        flex-direction: column;
        margin-right: 17px;

        img {
          width: 35px;
        }
      }

      .profile-name {
        font-family: SFUIText;
        font-size: 14px;
        color: white;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .seperator {
      width: 100%;
      height: 0;
      background-color: #d8d8d8;
      border-top: 1px solid #1a2e42;
      margin: 22px 0;
    }

    .profile-option {
      font-family: SFUIText;
      font-size: 14px;
      color: white;
      margin-bottom: 22px;
    }
  }

  .settings-options-box:after,
  .settings-options-box:before {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
  }

  /* this border color controlls the color of the triangle (what looks like the fill of the triangle) */
  .settings-options-box:after {
    top: -20px;
    right: 14px;
    border-color: transparent transparent #0c1927 transparent;
    border-width: 11px;
  }

  /* this border color controlls the outside, thin border */
  .settings-options-box:before {
    top: -22px;
    right: 14px;
    border-color: transparent transparent #00deff transparent;
    border-width: 11px;
  }
}

@keyframes customOpacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.profile-logo-box.show {
  visibility: visible;
}

.login-button-box {
  width: fit-content;
  visibility: hidden;
}

.login-button-box.show {
  visibility: visible;
}

.button-login {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 187px;
  height: 3vw;
  background-color: #fff;
  border-radius: 2px;
  transition: all ease 0.3s;

  &:hover {
    background-color: #d5dc36;
  }

  &.highlight {
    background: #ffffff40 0% 0% no-repeat padding-box;
  }

  &.locked {
    cursor: not-allowed;
  }

  .button-icon {
    display: flex;
    color: $tivibuSoftBlueColor;
    font-size: 40px;

    &.locked {
      color: gray;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  span {
    opacity: 0.9;
    font-family: SFUIText;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.29;
    letter-spacing: normal;
    text-align: left;
    color: #0c1927;
  }

  .seperator {
    width: 3px;
    height: 3px;
    background-color: #0c1927;
    margin: 0 5px;
  }
}

.menu-item {
  transition: all ease 0.3s;
  position: relative;
}

.search-field {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 15px;
  color: $tivibuSoftBlueColor;
  cursor: pointer;
  transition: all ease 0.3s;
  padding: 0 5px;
  margin-right: 20px;
}

.search-input-wrapper {
  position: relative;
  width: 40%;
  margin: auto;
  animation-name: inputFlow;
  animation-duration: 0.5s;
  margin-top: 18px;

  .search-input {
    border-radius: 28px;
    background: #122436;
    border: 1px solid #1c3148;
    padding: 17px 50px;
    padding-left: 80px;
    font-family: SFUIText;
    font-size: 16px;
    color: white;

    &::placeholder {
      font-family: SFUIText;
      font-size: 16px;
      color: rgba($color: white, $alpha: 0.33);
    }
  }

  .icon {
    position: absolute;
    color: white;

    &.icon-right {
      top: 19px;
      right: 30px;
      cursor: pointer;
    }

    &.icon-left {
      top: 17px;
      left: 30px;
    }
  }
}

@keyframes inputFlow {
  from {
    width: 0;
  }

  to {
    width: 40%;
  }
}
