@mixin responsive($query) {
    @media (max-width: + $query) {
        @content;
    }
}


$softBlue: #49BED8;

.channel-list-item {
    display: flex;
    width: 100%;
    height: 5.2vw;
    align-items: center;
    justify-content: center;
    background: transparent;
    cursor: pointer;
    position: relative;
    background: #0A1624;
    border: 1px solid #3D4E5A40;
    border-radius: 4px;
    margin-bottom: 1vw;

    &.selected {
        background-color: #152F3E;
        border-color: transparent;
    }

    .channel-logo {
        display: flex;
        align-items: center;
        width: 23%;
        height: 100%;
        padding: 10px;

        @include responsive(1400px) {
            padding: 5px;
        }

        .logo {
            height: auto;
            width: 100%;
        }
    }


    .channel-info {
        position: relative;
        text-align: left;
        width: 50%;
        padding: 10px;

        @include responsive(1400px) {
            padding: 5px;
        }

        .channel-name {
            font-family: SFUIText;
            color: white;
            font-size: 0.8vw;
            opacity: 1;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-bottom: 7px;
        }

        .program-name {
            font-family: SFUIText;
            color: #587086;
            font-size: 0.7vw;
            opacity: 1;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

    }

    .icons {
        display: flex;
        width: 27%;
        padding: 10px;

        @include responsive(1400px) {
            padding: 5px;
        }

        .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            background: #112134 0% 0% no-repeat padding-box;
            border: 0.5999999642372131px solid #3D4E5A80;
            border-radius: 50%;
            width: 1.5vw;
            height: 1.5vw;
            transition: all ease .3s;
            color: white;
            font-size: 12px;

            &:hover {
                color: #0C1927 !important;
                background-color: white;
            }

            &.fill-red {
                color: red;
            }

            &.fill-yellow {
                color: #D5DC36;
            }

            .channel-list-item-icons{
                width: 0.625vw !important;
                height: 0.625vw !important;
            }
        }

    }


}

.channel-play-icon,
.channel-lock-icon {
    display: flex;
    width: 5%;
    font-size: 30px;
    height: 85px;
    align-items: center;
    justify-content: center;
    color: $softBlue;
}

.channel-lock-icon {
    color: gray;
}


.colored {
    background: #FFFFFF1A 0 0 no-repeat padding-box;
}